.select_container{
    display: flex;
    align-items: center;
    padding-top:47px ;
    padding-left: 0;
    select{
        border-radius: 8px;
        border: 1px solid #CFE0F3;
        background: #FFF;
        max-width: 92px;
        width: 100%;
        height: 44px;
        padding: 0 12px;
        color: #8898AA;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
        text-transform: capitalize;
        &:focus{
            outline: none;
        }
    }
}
.form_input_field{
    margin-top: 37px;
    margin-bottom: 15px;
}
.plan_form_field{
    margin-top: 16px;
}
