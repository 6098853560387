.diet_page{
    padding: 38px 40px;
    .diet_head{
        padding-top:29px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .search_bar{
        text-align: right;
    }
}