.plan_detail_container{
    padding: 38px 41px;   
    .plan_form_section{
        border-radius: 10px;
        background: #FFF;
        box-shadow: 2px 2px 34px 0px rgba(0, 0, 0, 0.05);
        padding: 23px;
        margin-top: 36px;
        label{
            color: #525F7F;
            font-family: 'DM-Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
        }
        .course_section{
            text-align: left;
            .field{
                margin: 0 0 24px 0;
            }
            select{
                // margin: 12px 0 24px;
                width: 100%;
                max-width: 564px;
                height: 44px;
                border-radius: 8px;
                border: 1px solid #CFE0F3;
                background: #FFF;
                padding-left: 17px;
                color: #525F7F;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 45px;
                &:focus{
                    outline: none;
                }
            }
            .value{
                color: #525F7F;
                font-family: 'Inter';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
                margin-top: 12px;
                .icon_span{
                    font-size: 14px;
                    padding: 6px 8px 8px;
                    border-radius: 5px;
                    background: #CFE0F3;
                    margin-right: 13px;
                }
                .course_label{
                    margin-right: 36px;
                }
                .value_sec{
                    margin-left: 22px;
                }
            }
        }
        .course_paln{
            text-align: left;
            .payment_method{
                display: flex;
                .radio_field{
                    margin-right: 25px;
                    padding-right:10px;
                    color: #8898AA;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 45px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    input{
                        font-size: 30px;
                        cursor: pointer;
                        margin-right: 10px;
                    }
                }
            }
            .process_btn{
                button{
                    max-width: 119.855px;
                    width: 100%;
                    height: 44px;
                    border-radius: 4px;
                    background: #E91E23;
                    border: 1px solid #E91E23;
                    color: #FFF;
                    text-align: center;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-top: 40px;
                } 
            }
        }
    }
    .add_meal{
        border-radius: 10px;
        background: #FFF;
        box-shadow: 2px 2px 34px 0px rgba(0, 0, 0, 0.05);
        text-align: left;
        padding: 24px;
        margin-top: 36px;
        .meal_name{
            color: #32325D;
            font-family: 'Inter';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .meal_msg{
            color: #8898AA;
            text-align: center;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .meal_icon{
            color: #E91E23;
            font-size: 20px;
            line-height: 0;
            padding: 0 24px 0 14px;
        }
    }
    .succes_modal{
        text-align: center;
        .success_img{
            position: relative;
            img{
                position: absolute;
                bottom: 43px;
                left: 44%;
                text-align: center;
            }
        }
        .success_head{
            color: #32325D;
            font-family: 'Dm-sans';
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; 
            text-transform: capitalize;
            margin: 54px 0 20px;
        }
        .success_mg{
            color: #8898AA;
            text-align: center;
            font-family: 'Dm-sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; 
        }
        .modal_btn{
            max-width: 119.855px;
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background: #E91E23;
            border: 1px solid #E91E23;
            color: #FFF;
            text-align: center;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 40px;
        }
    }
}