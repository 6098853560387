.tab_wrapper{
    position: relative;
    .tab_options{
        display: flex;
        justify-content: left;
        margin-bottom: 25px;
        .options{
            width: 148px;
            border-bottom: 1px solid #dcdcdc;
            text-align: center;
            color: #525f7f;
            font-family: 'Dm-sans',sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            cursor: pointer;
        }
        .active{
            border-bottom: 2px solid #8D8D8D;
            color: #000;
            font-weight: 500;
        }
    }
    .table_wrapper{
        .btn_accept{
            background-color: #e91e23;
            border: 1px solid #e91e23;
            color: #fff;
            padding: 4px 16px;
            border-radius: 4px;
        }
        .status_approve{
            color: #e91e23;
            font-size: 14px;
            font-family: 'Dm-sans',sans-serif;
            padding-top: 12px;
        }
        .active_offer{
            background: #cbcbcb;
            text-align: center;
            padding: 10px;
            border-radius: 22px;
            color: #fff;
            max-width: 36px;
            width: 100%;
            height: 36px;
        }
    }
    .modal_section{
        .heading{
            text-align: left;   
            font-size: 18px;
            font-family: 'Dm-sans',sans-serif;
            font-weight: 500;
        }
        .content{
            font-size: 14px;
            font-family: 'Dm-sans',sans-serif;
            text-align: left;
            margin: 20px 0;
        }
        .modal_btns{
            display: flex;
            justify-content: right;
            gap: 20px;
            button{
                border: 1px solid #e91e23;
                color: #fff;
                padding: 4px 16px;
                min-width: 100px;
                border-radius: 4px; 
            }
            .btn_yes{
                background-color: #e91e23;
                color: #fff;
            }
            .btn_no{
                color: #e91e23;
            }
        }
    }
}