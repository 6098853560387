@font-face {
    font-family: 'DM-Sans';
    src:url('../../public/fonts/DM_Sans/static/DMSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: 'Inter';
    src:url('../../public/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.form_container{
    max-width: 630px;
    width: 100%;
    font-family: "DM-Sans";
    padding: 20px;
    border-radius: 4px;
    /* border: 1px solid #E0E0E0; */
    background: #FFF;
}
.seperator{
    color: #E9ECEF;
    margin: 11px 0 32px;
}
.form_head{
    color: #32325D;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}
.error_msg{
    color: #E91E23;
    font-family: "DM-Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 45px; */
    text-align: left;
    margin-bottom: 10px;
}
.form_container.edit-mode{
    border: none;
} 
.form_container.add-mode {
    border: 1px solid #E0E0E0; 
}
.button_wrapper{
  text-align: right;
  margin-top: 42px;
}
.input_add_btn{
    border-radius: 4px;
    background: #E91E23 !important;
    border: 1px solid #E91E23 !important;
    width: 119.855px;
    height: 44px;
    color: #FFF;
    text-align: center;
    font-family: "DM-Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-right: 24px;
}
.input_cancel_btn{
    color: #E91E23 !important;
    font-family: "DM-Sans";
    font-size: 14px;
    font-style: normal;
    background: none;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #E91E23 !important;
    width: 103px;
    height: 44px;
    margin-right: 24px;
    text-transform: capitalize;
}
.input_clear_btn{
    color: #E91E23 !important;
    font-family: "DM-Sans";
    font-size: 14px;
    font-style: normal;
    background: none;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #E91E23 !important;
    width: 103px;
    height: 44px;
    margin-right: 24px;
    text-transform: capitalize;
}
.form_wrapper{
    padding: 0 15px;
}
.form_wrapper_no_border {
    border: none;
    padding: 0;
}
.table_body_data{
    color: #525F7F !important;
    font-family: "DM-Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    padding: 21px !important;
}
.table_body_data p{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 154px;
    width: 100%;
}

.action_button{
    color: #8898AA;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    background: #FFF;
    border: none;
}
.dropdownContent{
    position: absolute;
    border: 1px solid #CFE0F3;
    background: #FFF;
    width: 120px;
    text-align: left;
    /* width: 100%; */
    padding: 14px 17px;
    right:89%;
    top: 26px;
    border-radius: 4px;
    z-index: 999;
}
.menu_dropdown {
    margin-left: 20px;
    position: relative;
}
.modal_head{
    color: #32325D;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    text-align: left;
    margin-bottom: 14px;
}
.modal_msg{
    color: #525F7F;
    font-family: "Dm-sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 42px;
    text-align: left;
}
.modal_no{
    border-radius: 4px;
    border: 1px solid #E91E23 !important;
    color: #E91E23;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 89px;
    height: 44px;
    width: 100%;
    background-color: #fff;
}
.modal_yes{
    max-width: 89px;
    height: 44px;
    width: 100%;
    border-radius: 4px;
    background: #E91E23 !important;
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #E91E23 !important;
    margin-right: 26px;
}
.form_img_field{
    text-align: left;
}
.label_img{
    border: 1px solid #c6cbd9;
    width: 100%;
    border-radius: 4px;
    height: 43px;
    cursor: pointer;
}
.label_img input{
    opacity: 0;
    width: 100%;
}
.image_upload {
    color: #525F7F;
    font-family: 'Dm-sans';
    font-size: 14px;
    font-style: normal;
    text-transform: capitalize;
    position: relative;
}
.upload{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 22px;
    width: 100%;
    padding: 0 10px;
}
.file_close{
    z-index: 999;
}
.selected_file_name{
    position: relative;
}
.selected{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 9px;
    width: 100%;
    padding: 0 10px;
    align-items: center;
}
.updated{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 10px;
    align-items: center;
}
.updated p{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.selected p{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.image_size{
    color: #8898AA;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 17px;
}
.page-link.active{
    background-color: #E91E23;
    border: 1px solid #E91E23;
    color: #fff;
}
.table_body_data img{
    width: 100%;
    max-width: 43px;
    height: 43px;
    border-radius: 43px;
    cursor: pointer;
}
.custom-dropdown {
    position: relative;
  }
  
  .dropdown {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 564px;
  }
  
  .dropdown-toggle {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #CFE0F3;
    background: #FFF;
    height: 44px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 4px;
    border-top: none;
  }
  
  .dropdown.open .dropdown-menu {
    display: block;
  }
  
  .dropdown-menu.show {
    animation: fadeIn 0.3s;
    width: 100%;
    padding: 10px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
.multi-select-dropdown input[type="checkbox"] {
    display: flex;
    justify-content: space-between;
    float: right;
}
.check-box-value{
    padding: 12px;
}
.active-status {
    color: #008E28 !important;
    font-size: 14px;
}
.pending-status {
    color: #FF9110 !important;
    font-size: 14px;
}
.rejected-status{
    font-size: 14px;
    color: #E91E23 !important;
}
.image_req {
    font-size: 14px;
    color: #E91E23;
}
.search_section{
    display: none;
}
.table_body_amount{
    color: #525F7F !important;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 21px !important;
}