.meal_page_container{
    margin: 0 20px;
    .plan_section{
        padding: 23px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 2px 2px 34px 0px rgba(0, 0, 0, 0.05);
        margin: 36px 0;
        .diet_row{
            margin: 0;
            .plan_column{
                padding: 0;
                .plan_item_list{
                    display:flex;
                    align-items: center;
                    margin: 15px 0;
                    width:100%;
                    font-family: "Inter";
                    text-transform: capitalize;
                    .plan_item_name{
                        color:#8898aa;
                        font-size:16px;
                        font-weight: 500;
                        flex:1;
                        text-align: left;
                        color: #8898AA;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    .plan_item_value{
                        color: #32325d;
                        font-size: 15px;
                        font-weight: 500;
                        flex:1;
                        text-align: left;
                        margin-left: 24px;
                        line-height: normal;
                    }
                }
             }
        }
    }
    .meal_add_section{
        padding: 23px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 2px 2px 34px 0px rgba(0, 0, 0, 0.05);
        margin: 36px 0;
        .meal_form_col{
            padding-left: 12px;
        }
        .meal_table{
            margin-top: 56px;
        }
    }
    .succes_modal{
        text-align: center;
        .success_img{
            position: relative;
            img{
                position: absolute;
                bottom: 43px;
                left: 44%;
                text-align: center;
            }
        }
        .success_head{
            color: #32325D;
            font-family: 'Dm-sans';
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; 
            text-transform: capitalize;
            margin: 54px 0 20px;
        }
        .success_mg{
            color: #8898AA;
            text-align: center;
            font-family: 'Dm-sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; 
        }
        .modal_btn{
            max-width: 119.855px;
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background: #E91E23;
            border: 1px solid #E91E23;
            color: #FFF;
            text-align: center;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 40px;
        }
    }
}