.layout {
  .nav_bar {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 250px;
    height: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    background-color: #fff;
    overflow: auto;
  }
  .body {
    margin-left: 250px;
    .content {
      border-radius: 10px;
      background: #fff;
      box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.08);
      padding: 20px 0px;
      // overflow: hidden;
    }
  }
}
