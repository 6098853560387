.tab_wrapper{
    position: relative;
    .tab_options{
        display: flex;
        justify-content: left;
        margin-bottom: 25px;
        .options{
            width: 148px;
            border-bottom: 1px solid #dcdcdc;
            text-align: center;
            color: #525f7f;
            font-family: 'Dm-sans',sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            cursor: pointer;
        }
        .active{
            border-bottom: 2px solid #8D8D8D;
            color: #000;
            font-weight: 500;
        }
    }
}