.pagination_container{
    display: flex;
    justify-content: right;
    margin-top: 59px;
}
:global{
    .pagination{
        li{
            border: 1px solid;
            padding: 9px 17px;
            margin: 5px;
            border-radius: 50%;
            border-color: #8898AA;
            color: #8898AA;
            a{
                font-size: 14px;
                color: #8898AA;
                line-height: 6px
            }
        }
        .previous , .next{
            padding: 7px 15px 7px;
            background-color: #fff;
            // border: 1px solid #E91E23;
            a{
                color: #8898AA;
                font-size: 12px;
            }
        }
        .active{
            padding: 9px 17px;
            background-color: #E91E23;
            border: 1px solid #E91E23;
            a{
                color: #fff;
                font-size: 12px;
            }
        }
        .disabled{
            display: none;
        }
    }
}