.payment_request_container{
    .head_wrapper{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .filter{
            width: 100%;
            max-width: 280px;
        }
        .search{
            margin-bottom: 8px;
        }
    }
    .content_wrapper{
        margin: 15px 0;
        .head{
            font-family: 'Dm-sans',sans-serif;
            font-size: 20px;
            font-weight: 400;
            color: #32325D;
            margin-bottom: 15px;
        }
    }
}