@import "../../styles/global.css";

.table_container{
    .table_wrapper{
        border-color: #E9ECEF;
        border-collapse: collapse;
        tr{
            text-align: left !important;
            // padding: 0 18px;
        }
        .table_head{
            .table_title{
                color: #8898AA;
                font-family: "DM-Sans";
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 18px ;
                background-color: #F6F9FC !important;
                // padding-left: 18px;
            }
        }
        // .table_body{
        //     .table_body_data{
        //         color: #525F7F;
        //         font-family: "DM-Sans";
        //         font-size: 14px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: normal;
        //         text-transform: capitalize;
        //         padding: 21px;
        //     }
        //     .table_btn{
        //         border: none;
        //         background-color: #fff;
        //         font-size: 20px;
        //         color: #525F7F;
        //     }
        // }
    }
}