
.input_wrapper{
    text-align: left;
    color: #525F7F;
    font-family: "DM-Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    .input_label{
        color: #525F7F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
        text-transform: capitalize;
        .required_star{
            color: #E91E23;
            font-size: 16px;
        }
    }
    .input_large{
        input{
            border-radius: 8px;
            border: 1px solid #CFE0F3;
            background: #FFF;
            max-width: 564px;
            width: 100%;
            height: 44px;
            padding: 17px 14px;
            color: #8898AA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            text-transform: capitalize;
            &:focus{
                outline: none;
            }
        }
    }
    .input_small{
        input{
            border-radius: 8px;
            border: 1px solid #CFE0F3;
            background: #FFF;
            max-width: 264px;
            width: 100%;
            height: 44px;
            padding: 17px 14px;
            color: #8898AA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            text-transform: capitalize;
            &:focus{
                outline: none;
            }
        }
    }
    .input_extra_small{
        input{
            border-radius: 8px;
            border: 1px solid #CFE0F3;
            background: #FFF;
            max-width: 80px;
            width: 100%;
            height: 44px;
            text-align: center;
            padding: 17px 14px;
            color: #8898AA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            text-transform: capitalize;
            &:focus{
                outline: none;
            }
        }
    }
}