label{
    color: #525F7F;
    font-family: 'Dm-sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    @media screen and (min-width:1400px) {
        font-size: 15px;
    }
}