.search{
    text-align: right;
    width: 100%;
        form{
            display: flex;
            justify-content: right;
            .search_icon{
                border-radius: 4px;
                background: #E91E23;
                font-size: 20px;
                border:1px solid #E91E23;
                margin-left: 10px;
                color: #fff;
                width: 46px;
                height: 45px;
                margin-bottom: 29px;
            }
            input{
                height: 46px;
                width: 100%;
                max-width: 320px;
                border-radius: 8px;
                border: 1px solid #CFE0F3;
                padding-left: 14px;
                color: #8898AA;
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 45px;
                margin-right: 12px;
                &:focus{
                    outline: none;
                }
            }
            .payment_input{
                max-width: 480px;
                width: 100%;
            }
            .search_btn{
                font-size: 20px;
                height: 46px;
                border-radius: 4px;
                background: #E91E23;
                border: 1px solid #E91E23;
                color: #fff;
                padding: 3px 13px 8px;
            }
            .payment_btn{
                background: #8D8D8D;
                border: 1px solid #8D8D8D;
            }
        }
}