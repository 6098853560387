.image_field{
    .img_label{
        color: #525F7F;
        font-family: 'Dm-sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
        text-align: left;
        margin-top: 29px;
    }
    .add_img_block {
        border-radius: 8px;
        border: 1px solid #CFE0F3;
        background: #FFF;
        max-width: 564px;
        width: 100%;
        height: 44px;
        text-align: left;
        .label_img {
            cursor: pointer;
            padding: 5px 15px;
            margin: 5px;
            display: inline-block;
            background:none;
            color: #8898AA;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:valid+ span {
                color: #ffffff;
            }
            &:input[type="file"]{
                position: absolute;
                top: 54px;
                opacity: 0;
                width: 100%;
                left: 0;
                cursor: pointer;
            }
        }
    }
}
