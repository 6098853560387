@import '../../styles/global.css';
p{
    color: #8898AA;
    font-family: "DM-Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.form_field{
    .fld_input{
        margin-bottom: 34px;
    }
    .img_label{
        color: #525F7F;
        font-family: 'Dm-sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
        text-align: left;
    }
}
.inner_form{
    margin-bottom: 34px;
    .select_wrapper{
        text-align: left;
        .select_label{
            color: #525F7F;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            text-transform: capitalize;
        }
        select{
            border-radius: 8px;
            border: 1px solid #CFE0F3;
            background: #FFF;
            max-width: 264px;
            width: 100%;
            height: 44px;
            padding: 11px 17px 14px 14px ;
            color: #8898AA;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            text-transform: capitalize;
            &:focus{
                outline: none;
            }
        }
    }
    .warning{
        color: #525F7F;
        font-family:'Dm-sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        // line-height: 45px;
        margin-top: 12px;
        text-align: left;
    }
    .unit_add{
        display: flex;
        gap: 10px ;
        .unit_box{
            margin-top: 12px;
            margin-right: 12px;
            .unit_labels{
                display: flex;
                justify-content: space-between;
                max-width: 176px;
                width: 100%;
                margin-bottom: 9px;
                font-family: 'Dm-sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                .label{
                    // line-height: 45px; 
                    color: #525F7F;
                }
                button{
                    border: none;
                    text-align: center;
                    background-color: #CFE0F3;
                    color: #32325D;
                    border-radius: 4px;
                    padding: 4px 6px;
                    line-height: 0;
                }
            }
            input{
                border-radius: 8px;
                border: 1px solid #CFE0F3;
                background: #FFF;   
                color: #8898AA;
                font-family: "Dm-sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 45px;        
                max-width: 132px;
                height: 44px;
                width: 100%;     
                padding: 17px;
                &:focus{
                    outline: none;
                }
            }
        }
    }
    .btn_wrapper{
        display: flex;
        justify-content: left;
        padding-top: 45px;
        button{
            border-radius: 4px;
            background: #E91E23;
            border: 1px solid #E91E23;
            width: 85px;
            height: 36px;
            color: #FFF;
            text-align: center;
            font-family: "DM-Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }
}
.button_wrapper{
    margin-top: 19px;
    text-align: right;
}