.heading{
    color: #000;
    font-family: 'Dm-sans',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
}
.content{
    text-align: left;
    color: #525f7f;
    font-family: 'Dm-sans',sans-serif;
    font-size: 14px;
}
.modal_btns{
    display: flex;
    justify-content: right;
    gap: 20px;
    margin-top: 30px;
    button{
        padding: 6px 16px;
        max-width: 92px;
        width: 100%;
        border-radius: 4px;
    }
    .btn_yes{
        background: #8D8D8D;
        color: #fff;
    }
    .btn_no{
        border: 1px solid #8d8d8d;
        color: #8d8d8d;
    }
}