.tab_wrapper{
    position: relative;
    .tab_options{
        display: flex;
        justify-content: left;
        margin-bottom: 25px;
        .options{
            width: 148px;
            border-bottom: 1px solid #dcdcdc;
            text-align: center;
            color: #525f7f;
            font-family: 'Dm-sans',sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            cursor: pointer;
        }
        .active{
            border-bottom: 2px solid #8D8D8D;
            color: #000;
            font-weight: 500;
        }
    }
    .btn_download{
        position: absolute;
        right: 0;
        top: 0;
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            background: #E91E23;
            border: 1px solid #E91E23;
            color: #fff;
            border-radius: 6px;
            padding: 6px 10px;
            font-size: 14px;
            span{
                padding-bottom: 2px;
            }
        }
    }
    .btn_store{
        position: absolute;
        right: 140px;
        top: 0;
        button{
            background: #8d8d8d;
            border: 1px solid #8d8d8d;
            color: #fff;
            border-radius: 6px;
            padding: 7px 10px; 
            font-size: 14px;
        }
        .disabled{
            background-color: #dcdcdc;
            border: 1px solid #DCDCDC;
            cursor: not-allowed;
        }
    }
}