.plan_details {
  .list_item {
    display: flex;
    align-items: center;
    margin: 15px 0;
    width: 100%;
    font-family: "Inter";
    text-transform: capitalize;
    .item_name {
      color: #8898aa;
      font-size: 16px;
      font-weight: 500;
      flex: 1;
      text-align: left;
      color: #8898aa;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .item_value {
      color: #32325d;
      font-size: 15px;
      font-weight: 500;
      flex: 1;
      text-align: left;
      margin-left: 24px;
      line-height: normal;
    }
  }
}
