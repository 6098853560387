.plan_detail_conainer{
    padding: 23px;
    width: 100%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 2px 34px 0px rgba(0, 0, 0, 0.05);
    .page_head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .button_section{
            button{
                border-radius: 4px;
                background: #E91E23;
                border: 1px solid #E91E23;
                width: 119.855px;
                height: 44px;
                color: #FFF;
                text-align: center;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }
        }
    }
    .diet_edit{
        color: #32325D;
        font-family:  "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
        span{
            margin-left: 36px;
            .edit_button{
                border: none;
                background: none;
            }
        }
    }
    .plan_detail{
        .item_list{
            display:flex;
            align-items: center;
            margin: 15px 0;
            width:100%;
            font-family: "Inter";
            text-transform: capitalize;
            .item_name{
                color:#8898aa;
                font-size:16px;
                font-weight: 500;
                flex:1;
                text-align: left;
                color: #8898AA;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .item_value{
                color: #32325d;
                font-size: 15px;
                font-weight: 500;
                flex:1;
                text-align: left;
                margin-left: 24px;
                line-height: normal;
            }
            .weight_value{
                font-size: 15px;
                font-weight: 500;
                flex:1;
                text-align: left;
                margin-left: 24px;
                line-height: normal;
                color: #008E28;
            }
        }
    }
}


