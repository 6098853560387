.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  text-align: right;
  overflow: auto;
  min-height: 100%;
  padding: 30px;
  .modal_content {
    background-color: #fff;
    padding: 25px;
    width: 630px;
    position: relative;
    border-radius: 10px;
  }
  
  button {
    background: none;
    border: none;
  }
  @media screen and (min-width:992px){
    .large_modal{
      // margin-top: 300px; //mealpage
      margin-top:200px; //food edit
    }
  }
}
