@import "../../styles/global";

.form_container {
  padding: 20px 11px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  .form_wrapper {
    .calory_field {
      margin-top: 29px;
    }
    :global{
      .meal__value-container {
        input {
          height: 30px !important;
        }
      }
    }
    .time_field {
      label {
        font-size: 14px;
      }
      .select_fld {
        display: flex;
        align-items: center;
        padding-top: 42px;
        select {
          border-radius: 8px;
          border: 1px solid #cfe0f3;
          background: #fff;
          max-width: 80px;
          width: 100%;
          height: 44px;
          padding: 8px;
          color: #8898aa;
        }
      }
    }
    .form_small {
      gap: 0 10px;
      margin-top: 48px;
      .select_wrapper {
        max-width: 85px;
      }
    }
    .select_wrapper {
      // text-align: left;
      // display: flex;
      // align-items: center;
      // flex-direction: column;
      // padding: 0;
      //   max-width: 85px;
      width: 100%;
      text-align: left;
      label {
        color: #525f7f;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 14px 0;
        margin-left: 0;
      }
      select {
        border-radius: 8px;
        border: 1px solid #cfe0f3;
        background: #fff;
        max-width: 264px;
        height: 44px;
        width: 100%;
        color: #8898aa;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      input {
        //   margin-top: 68px;
        width: 100%;
        height: 44px;
        font-size: 14px;
      }
    }
    .select_unit_wrapper {
      padding: 0;
      max-width: 85px;
      width: 100%;
      select {
        border-radius: 8px;
        border: 1px solid #cfe0f3;
        background: #fff;
        max-width: 85px;
        height: 44px;
        width: 100%;
        color: #8898aa;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        //   margin-top: 68px;
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      padding: 0;
      max-width: 85px;
      width: 100%;
      button {
        //   margin-top: 62px;
        height: 36px;
        width: 100%;
        color: #fff;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        border-radius: 4px;
        background: #e91e23;
        border: 1px solid #e91e23;
      }
    }
    .selected_meals {
      margin-top: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: #f6f9fc;
      padding: 19px 10px;
      color: #888888;
      .strong {
        color: #222222;
      }
      .trash_btn {
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        background: transparent;
      }
    }
  }
}
