.nav_item_wrapper {
    user-select: none;
  .nav_item {
    padding: 1rem 1.5rem;
    cursor: pointer;
    display: flex;
    gap: 0 10px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .arrow {
      transition: all ease 0.5s;
      &.open {
        transform: rotate(90deg);
        transition: all ease 0.5s;
      }
    }
    .title_wrapper {
      display: flex;
      align-items: center;
      gap: 0 22px;
    }
    &:hover,
    &.active {
      background-color: #f0f9fd;
    }
    span {
      line-height: 16px;
    }
  }
  .sub_list_items {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 0;
    visibility: hidden;
    // transition: all ease 0.5s;
    &.open {
      height: 100%;
      visibility: visible;
    //   transition: all ease 0.5s;
    }
    a {
      color: inherit;
      text-decoration: none;
      display: inline-block;
      padding: 1rem 1.5rem;
      padding-left: 3.8rem;
      width: 100%;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: #f0f9fd;
      }
    }
  }
}
