.table_wrapper{
    border-color: #E9ECEF;
    border-collapse: collapse;
    tr{
        text-align: left !important;
        position: relative;
        .pay_now{
            background: #8d8d8d;
            border: 1px solid #8d8d8d;
            color: #fff;
            border-radius: 6px;
            padding: 6px 5px;
            position: absolute;
            max-width: 84px;
            width: 100%;
            right: 25px;
            font-size: 14px;
            top: 7px;
        }
    }
    .table_head{
        .table_title{
            color: #8898AA;
            font-family: "DM-Sans";
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            padding: 18px ;
            background-color: #F6F9FC !important;
        }
    }
}