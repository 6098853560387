.multi_select_box {
  user-select: none;
  position: relative;
  .selected_items {
    display: flex;
    gap: 0 10px;
    margin-bottom: 10px;
    .selected_item {
        background-color: #e5eaef;
        color: #000;
        padding: 5px 10px;
        border-radius: 8px;
    }
  }
  .select_box {
    width: 100%;
    border: 1px solid #cfe0f3;
    border-radius: 8px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 5px;
  }
  .drop_down {
    border: 1px solid #cfe0f3;
    min-height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    background: #ffffff;
    margin-top: 3px;
    z-index: 1;
    .option {
      display: flex;
      padding: 0 10px;
      &:hover {
        background-color: #f6f9fc;
      }
      label {
        padding-left: 10px;
        display: inline-block;
        width: 100%;
      }
    }
  }
}
