@import '../../styles/global.css';

.heading{
    color: #32325D;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    // margin-bottom: 32px;
    text-transform: capitalize;
}