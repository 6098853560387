.filter_wrapper{
    width: 100%;
    position: relative;
    button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 320px;
        width: 100%;
        background-color: #fff;
        border: none;
        border-radius: 8px;
        padding: 12px 20px;
        box-shadow: 0 1px 5px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
        font-family: 'Dm-sans',sans-serif;
        font-size: 14px;
        margin-bottom: 6px;
    }
    .heading{
        font-family: 'Dm-sans',sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        color: #525F7F;
    }
    .filter_option_wrapper{
        position: absolute;
        background-color: #fff;
        box-shadow: 0 1px 5px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
        padding: 16px 20px;
        max-width: 320px;
        width: 100%;
        z-index: 1;
        .filter_options{
            padding-bottom: 18px;
            font-family: 'Dm-sans',sans-serif;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }
        .round{
            border: 1px solid #8d8d8d;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            position: relative;
            .check{
                position: absolute;
                left: 0px;
                bottom: -1px;
                font-size: 14px;
                color: #fff;
                background: #8d8d8d;
                border-radius: 50%;
                width: 18px;
                height: 20px;
                .tik{
                    position: absolute;
                    left: 2px;
                    bottom: 0px;
                }
            }
        }
    }
}