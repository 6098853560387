.nav {
  height: 100%;
  padding: 1rem 1.5rem;
  .header {
    text-align: center;
    border-bottom: 1px solid #e9ecef !important;
    a {
      max-width: 40px;
      width: 100%;
      display: inline-block;
      padding: 1rem 0;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav_items_wrapper {
    .nav_items {
      margin: 0;
      padding: 0;
      list-style: none;
      margin: 1rem -1.5rem;
      font-size: 0.93rem;
      color: #8898aa;
      &::before {
        content: "";
        display: inline-block;
        margin-top: 1rem;
      }
      .nav_item {
        padding: 1rem 1.5rem;
        cursor: pointer;
        display: flex;
        gap: 0 10px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .title_wrapper{
          display: flex;
          align-items: center;
          gap: 0 22px;
        }
        &:hover {
          background-color: #f0f9fd;
        }
        span {
          line-height: 0;
        }
      }
      .sub_list_items {
        list-style: none;
        padding: 0;
        margin: 0;
        a {
          color: inherit;
          text-decoration: none;
          display: inline-block;
          padding: 1rem 1.5rem;
          padding-left: 3.8rem;
          width: 100%;
          color: rgba(0, 0, 0, 0.5);
          &:hover {
            background-color: #f0f9fd;
          }
        }
      }
    }
  }
}
