.form_fields {
  text-align: left;
  margin-bottom: 29px;
  .form_field {
    margin-bottom: 20px;
  }
  input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid #cfe0f3;
    border-radius: 8px;
    padding: 5px;
  }
  label {
    color: #525f7f;
    font-family: "Dm-sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
  }
  select {
    height: 44px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #cfe0f3;
    background: #fff;
    &:focus {
      outline: none;
    }
  }
}
.button_wrapper {
  text-align: right;
  button {
    width: 103px;
    height: 44px;
    border-radius: 4px;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .cancel_btn {
    border: 1px solid #e91e23;
    background: none;
    color: #e91e23;
    margin-right: 24px;
  }
  .submit_btn {
    background: #e91e23;
    border: 1px solid #e91e23;
    color: #fff;
  }
}
