.diet_container{
    padding: 20px 40px;
    // height: 769px;
    background-color: #fff;
    .diet_page_head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }
}